import React, { useState, useEffect, useContext } from 'react';
import ReactHTMLParser from 'react-html-parser';
import { marked } from 'marked';
import { Box } from '@latitude/box';
import { AccordionSidebar } from '@latitude/accordion';
import { Heading4, Heading5 } from '@latitude/heading';
import { ImportantInformation } from '@latitude/important-information';
import { List, ListItem } from '@latitude/list';
import { Metadata } from '@latitude/metadata';
import { FeaturesSlider } from '@latitude/features-slider';
import { Text } from '@latitude/text';
import ListNumbered from '@latitude/list-numbered/ListNumbered';
import FramedContainer from '@latitude/framed-container/FramedContainer';
import {
  ALIGN,
  BREAKPOINT,
  COLOR,
  HEADING,
  MARGIN,
  PADDING,
  FONT_SIZE
} from '@latitude/core/utils/constants';
import Layout from '@/components/layout';
import { SITE_URL } from '@/utils/constants';
import Hero from './_hero';
import faqs from './_faqs';
import StickyNavigation from './_sticky-navigation';
import imgHowToUse from './images/nz_digital_wallet-google-pay-phone.png';
import { PageContext } from '../../context/PageContext';
import { CONTENTFUL_COMPONENTS } from '../../utils/constants';
import { useSetState } from '@/utils/hooks';

const GooglePay = ({ location }) => {
  const featureSliderItems = [
    {
      icon: 'icon-easy',
      title: 'Simple',
      text:
        'Thanks to Google Pay, you can now use your Gem Visa card in just a tap with your compatible Google device.<sup>&gt;</sup>'
    },
    {
      icon: 'icon-padlock-confetti',
      title: 'Secure',
      text:
        'Feel at ease with security features like immediate payment tracking, virtual account numbers and Google Device Manager to lock or erase your phone should it be lost.'
    },
    {
      icon: 'icon-tick-circle-confetti',
      title: 'Convenient',
      text:
        'Google Pay can be used wherever you see the contactless payments or Google Pay symbols at checkouts in-store, or the Buy with Google Pay option in apps and online.'
    }
  ]

  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);

  const [state, setState] = useSetState({
    featureSliderData: [{
      heading: "Why Google Pay?",
      featureCards: featureSliderItems
    }]
  });

  useEffect(() => {
    let [featureSliders] = [[]];
    contentfulPageData?.body?.map(item => {
      switch (item.contentType.sys.id) {
        case CONTENTFUL_COMPONENTS.FEATURE_SLIDER:
          const updatedItem = {
            heading: marked(item.heading || ""),
            description: ReactHTMLParser(marked(item.description || "")),
            featureCards: item.featureCards?.map(card => ({
              ...card,
              text: marked(card.text || ""),
            }))
          };
          featureSliders = [...featureSliders, updatedItem];
          break;
        default:
          break;
      }
    });

    setState({ 
      featureSliderData: featureSliders.length > 0 ? featureSliders : state.featureSliderData,
    });
  }, [contentfulPageData]);
  /** - END - */
  
  return(
    <Layout location={location}>
      <Metadata
        title="Google Pay &amp; Wallet Setup | Mobile Tap &amp; Pay | Gem by Latitude"
        description="Google Pay is now available to Gem Visa customers. See how to setup Google Pay on your Android devices and make your payments more simple and secure."
        canonical={`${SITE_URL}${location.pathname}`}
      />
      <Hero />
      <StickyNavigation />
      {state?.featureSliderData?.[0] && (
        <Box backgroundColor={COLOR.WHITE}>
          <FeaturesSlider
            heading={state.featureSliderData[0].heading}
            subheading={state.featureSliderData[0].description}
            data={state.featureSliderData[0].featureCards}
          />
        </Box>
      )}
      <Box.Section backgroundColor={COLOR.GREY6}>
        <Heading4 align={ALIGN.CENTER} color={COLOR.BLACK}>
          Set up and use Google Pay
        </Heading4>
        <Box
          css={`
            display: grid;
            grid-gap: ${MARGIN.M24} ${MARGIN.M40};
            h5 {
              margin-bottom: ${MARGIN.M16};
              color: ${COLOR.BLACK};
            }
            @media (max-width: ${BREAKPOINT.SM}) {
              h5 {
                font-size: ${HEADING.h6.FONT_SIZE.NORMAL};
                line-height: ${HEADING.h6.LINE_HEIGHT.NORMAL};
              }
            }
            @media (min-width: ${BREAKPOINT.LG}) {
              grid-template-columns: repeat(3, 1fr);
              grid-template-rows: 1;
            }
          `}
        >
          <Box
            css={`
              text-align: center;
              @media (min-width: ${BREAKPOINT.LG}) {
                grid-area: 1/1;
              }
            `}
          >
            <img
              css={`
                max-width: 280px;
              `}
              src={imgHowToUse}
              alt="Phone - Google Pay"
            />
          </Box>
          <Box>
            <Heading5>Set up on phone</Heading5>
            <List>
              <ListItem>
                Download the Google Pay app free from the Google Play Store.
              </ListItem>
              <ListItem>
                Open the Google Pay app and tap the ‘+’ sign to add a card.
              </ListItem>
              <ListItem>
                Use your phone camera to capture your card&apos;s information or
                enter it manually.
              </ListItem>
              <ListItem>
                Follow the prompts to complete the details and tap
                &apos;Save&apos; to confirm that you agree to the Google Terms and
                Conditions.
              </ListItem>
              <ListItem>
                Read and confirm that you agree to the Gem Visa card with Google
                Pay Terms and Conditions.
              </ListItem>
              <ListItem>
                For security purposes we may ask you to call us or send you a
                verification code via SMS.
              </ListItem>
            </List>
          </Box>
          <Box>
            <Heading5>How to use</Heading5>
            <List>
              <ListItem>Wake your Google device.</ListItem>
              <ListItem>
                Hold your phone close to the payment terminal to pay and away you
                go.
              </ListItem>
              <ListItem>
                If your transactions is over $100 you may be prompted to input
                your card PIN.
              </ListItem>
            </List>
          </Box>
        </Box>
      </Box.Section>
      <Box.Section
        isResponsive
        isBodyCopy
        css={`
          padding: 8px 0;
          @media (min-width: ${BREAKPOINT.LG}) {
            padding: 40px 0;
          }
        `}
        backgroundColor={COLOR.BLUE_BABY}
      >
        <Box padding="0 15px">
          <Heading4 align={ALIGN.CENTER} color={COLOR.BLACK}>
            Just got approved? You don&apos;t have to wait for your card to start
            spending.
          </Heading4>
          <Box marginBottom={MARGIN.M32}>
            <Text align={ALIGN.CENTER}>
              If you have a Gem Visa card you can start using your card instantly
              via Google Pay. Here&apos;s how:
            </Text>
          </Box>
          <FramedContainer
            theme={{
              frameColor: COLOR.BLUE_LIGHT_BRIGHT,
              contentBorderColor: 'none'
            }}
          >
            <Box padding={PADDING.P24}>
              <ListNumbered
                data={[
                  'First, download the&nbsp;<a href="https://play.google.com/store/apps/details?id=com.latitudefinancial.latitudeapp" style="display: contents; text-decoration: underline;">Latitude App</a>&nbsp;and create your Latitude ID.',
                  'Link your Gem Visa card using your account number (you&apos;ll find this in the email we sent to you when you were approved). You&apos;ll receive a verification SMS with a four-digit code to insert.',
                  'Next, go to your Google Pay wallet on your phone and insert your name and date of birth. You may receive a verification SMS at this stage.',
                  'Start using your card instantly through Google Pay.'
                ]}
                viewStyle="compact"
                counterBgColor={COLOR.BLUE_DEEP}
                counterVerticalAlign
                separator="light"
              />
              <div>
                <hr className="mt-3 mb-4 light-border" />
                <Text fontSize={FONT_SIZE.SMALL}>
                  It&apos;s important to be aware that you might be limited to
                  transactions under $80 until your card arrives in the post, you
                  activate it and set a PIN.
                </Text>
              </div>
            </Box>
          </FramedContainer>
        </Box>
      </Box.Section>
      <Box.Section>
        <AccordionSidebar data={faqs.googlePay} />
      </Box.Section>
      <ImportantInformation
        data={{
          content: {
            importantInformationSectionOne: [
              '<sup>&gt;</sup> Google Pay is available on compatible Android phones with Near Field Communications (NFC) technology. <a href="https://support.google.com/pay/answer/7625055" target="_blank">https://support.google.com/pay/answer/7625055</a>'
            ],
            importantInformationSectionTwo: [
              'Android, Google Pay and Google Play are trademarks of Google Inc.'
            ]
          }
        }}
        sectionOneColummClasses="col-10 offset-1 col-lg-5 offset-lg-1"
        sectionTwoColummClasses="col-10 offset-1 col-lg-5 offset-lg-0"
      />
    </Layout>
  );
}

export default GooglePay;
